<template>
  <v-card class="mt-4 mx-auto">
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-sheet
      class="v-sheet--offset mx-auto"
      color="success"
      elevation="12"
      max-width="calc(100% - 32px)"
    >
      <v-card
        dark
        color="color_green"
      >
        <v-card-title> Applications </v-card-title>
      </v-card>
    </v-sheet>
    <v-card-text class="pt-0">
      <v-data-table
        id="services-table"
        :headers="services_headers"
        :items="services.services"
        :search="search"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
            <v-spacer />
            <v-btn
              color="color_green"
              dark
              @click="createButtonEvent"
            >
              New Application
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.service_type`]="{ item }">
          <service-type :service-type="item.service_type" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editButtonEvent(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            v-if="item.service_type != 'B2B'"
            small
            class="mr-1"
            @click="configurationButtonEvent(item)"
          >
            mdi-cog
          </v-icon>
          <v-icon
            v-if="item.service_type != 'B2B'"
            small
            class="ml-1"
            @click="translationButtonEvent(item)"
          >
            mdi-google-translate
          </v-icon>
          <delete
            :id="item.name"
            @deleted="deleteButtonEvent(item)"
          />
        </template>
        <template v-slot:no-data>
          <span color="primary">No data to show you</span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    delete: () => import('@/components/utils/Delete'),
    ServiceType: () => import('@/components/core/ServiceType')
  },

  data() {
    return {
      search: '',
      loading: false,
      overlay: false,
      services_headers: [
        {
          text: 'Application Name',
          value: 'service_name'
        },
        {
          text: 'Description',
          value: 'description'
        },
        {
          text: 'Type',
          value: 'service_type'
        },
        {
          text: 'State',
          value: 'service_state'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: 130
        }
      ]
    };
  },
  computed: {
    ...mapState(['services', 'wsService'])
  },
  mounted() {
    this.wsService.selectedWsConfiguration = [];
    this.overlay = true;
    this.storeServices();
    this.overlay = false;
  },
  methods: {
    ...mapActions(['deleteServiceAction', 'storeServices']),
    async deleteButtonEvent(item) {
      this.overlay = true;
      await this.deleteServiceAction(item);
      this.overlay = false;
      this.storeServices();
    },
    editButtonEvent(item) {
      this.$emit('editServiceEvent', item);
    },
    createButtonEvent() {
      this.$emit('createServiceEvent');
    },
    configurationButtonEvent(item) {
      this.$emit('configurationServiceEvent', item);
    },
    translationButtonEvent(item) {
      this.$emit('translationServiceEvent', item);
    }
  }
};
</script>

<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>
